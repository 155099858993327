import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { heroVideo, smallHeroVideo } from '../utils';
import { useEffect, useState } from 'react';
import { Toaster } from "react-hot-toast";
import EmailForm from "./EmailFom";


const Hero = () => {
  const [videoSrc, setVideoSrc] = useState(heroVideo);
  const [sectionMarginTop, setSectionMarginTop] = useState(null); // Add state for margin-top
  const [sectionMarginButton, setSectionMarginButton] = useState(null); // Add state for margin-top

  const handleVideoSrcSet = () => {
    if (window.innerWidth < 760) {
      setVideoSrc(smallHeroVideo);
    } else {
      setVideoSrc(heroVideo);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setSectionMarginTop(window.innerWidth < 760 ? '15vh' : '-15vh');
      setSectionMarginButton(window.innerWidth < 760 ? '15vh' : null);

    };

    handleResize(); // Initialize margin-top on first render
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useGSAP(() => {
    gsap.to('#hero', { opacity: 1, delay: 2 });
    gsap.to('#cta', { opacity: 1, y: -50, delay: 2 });
  }, []);

  return (
    <>
      <Toaster />
      <section
        className="w-full h-screen flex flex-col md:flex-row items-center justify-center mx-auto"
        style={{ marginTop: sectionMarginTop, marginBottom:  sectionMarginButton }}
      >
        <div className="md:w-5/12 h-full flex justify-center items-center mx-5">
          <video
            className="rounded-lg shadow-xl max-h-full max-w-full"
            autoPlay
            muted
            playsInline
            key={videoSrc}
          >
            <source src={videoSrc} type="video/mp4" />
          </video>
        </div>
        <div className="md:w-5/12 flex flex-col gap-8 justify-center p-4 mx-5">
          <h1 className="font-semibold text-zinc-900 text-3xl md:text-4xl">
            Descubre, Crea y Conecta en nuestra Plataforma UGC
          </h1>
          <p className="text-gray-500">
            Únete a nuestra lista de espera y sé el primero en aprovechar las nuevas oportunidades de colaboración.
          </p>
          <EmailForm />
        </div>

      </section>
    </>
  );
};

export default Hero;

