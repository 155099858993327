import { InfoCircledIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import toast from "react-hot-toast";

function EmailFormLast() {
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://ii97gcsb22.execute-api.us-east-1.amazonaws.com/prod/ugc-waitlist", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "body": JSON.stringify({ "email": email }) }),
      });
      console.log('Body request ', JSON.stringify({ "body": JSON.stringify({ "email": email }) }));

      const data = await response.json();
      console.log('Response ', data);
      if (response.status === 200 && data.body === '"Email successfully added to the waitlist!"') {
        setEmail("");
        toast.success("¡Gracias por unirte a nuestra lista de espera! 🚀");
      } else {
        throw new Error('Error adding email to the waitlist');
      }
    } catch (err) {
      console.error(err);
      toast.error("Ocurrió un error al intentar añadir tu correo a la lista de espera.");
    }
  };

  return (
    <>
      <h2 className="text-2xl font-semibold text-center mb-4 md:text-3xl">
        ¡Únete a la revolución de creadores y marcas!
      </h2> {/* Attractive title with font size adjustments for responsiveness */}

      <form onSubmit={handleSubmit} className="mt-2 w-full max-w-lg mx-auto px-4">
        <div className="flex flex-col sm:flex-row gap-4">
          <input
            type="email"
            name="email"
            id="email-address"
            placeholder="johndoe@example.com"
            required
            autoComplete="email"
            className="flex-1 min-w-0 block w-full px-4 py-2 text-base text-gray-900 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            value={email}
            onChange={handleEmailChange}
          />
          <button
            type="submit"
            className="flex-shrink-0 px-6 py-2 text-base font-semibold text-white bg-blue-600 border border-white rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:border-blue-700 focus:ring-1 focus:ring-blue-700"
          >
            Únete a la lista de espera
          </button>
        </div>
      </form>

      <div className="text-center mt-4">
        <div className="flex justify-center space-x-2"> {/* Increased space between avatars */}
          <img src="/assets/images/avatar1.jpeg" alt="User" className="w-10 h-10 rounded-full" /> {/* Increased size for avatars */}
          <img src="/assets/images/Avatar2.jpeg" alt="User" className="w-10 h-10 rounded-full" />
          <img src="/assets/images/Avatar3.jpeg" alt="User" className="w-10 h-10 rounded-full" />
          <img src="/assets/images/Avatar4.jpeg" alt="User" className="w-10 h-10 rounded-full" />
          <img src="/assets/images/Avatar6.jpeg" alt="User" className="w-10 h-10 rounded-full" />
        </div>
        <p className="text-sm md:text-base">
          Únete a los 500+ usuarios que ya están descubriendo nuevas colaboraciones.
        </p>
      </div>

      <div className="mt-4 text-center text-gray-600 flex items-center justify-center"> {/* Centered the text and icon */}
        <InfoCircledIcon className="inline-block w-5 h-5 mr-2 text-gray-500 align-text-top" />
        <span className="text-sm">
          Tus datos están seguros con nosotros. Solo los utilizaremos para mantenerte informado sobre nuestra plataforma.
        </span>
      </div>
    </>
  );
}

export default EmailFormLast;
