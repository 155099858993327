import { useState, useEffect } from 'react';
import { appleImg } from '../utils';
import { navLists } from '../constants';

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false); // Use media query for initial value

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 760);
    };
    handleResize(); // Initialize on first render
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>

        {!isMobile && ( // Conditionally render the navbar only when not mobile
          <header className="w-full py-2 sm:py-4 px-4 bg-black text-white">
            <nav className="flex items-center justify-between max-w-screen-xl mx-auto">
              <div className="flex items-center">
                <a href="/">
                  <img src={appleImg} alt="Apple" className="w-12 sm:w-20 h-auto" />
                </a>
              </div>

              <div className="hidden sm:flex items-center space-x-6">
                {/* {navLists.map((nav) => (
                  <a
                    key={nav}
                    href={`#${nav.toLowerCase()}`}
                    className="px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-800 hover:text-white transition-colors"
                  >
                    {nav}
                  </a>
                ))} */}
              </div>

      
            </nav>
          </header>
        )}
    </>
  );
};

export default Navbar;
