import { useGSAP } from '@gsap/react'
import React, { useRef } from 'react'
import { animateWithGsap } from '../utils/animations';
import { explore1Img, explore2Img, exploreVideo } from '../utils';
import gsap from 'gsap';

const Features = () => {
  const videoRef = useRef();

  useGSAP(() => {
    gsap.to('#exploreVideo', {
      scrollTrigger: {
        trigger: '#exploreVideo',
        toggleActions: 'play pause reverse restart',
        start: '-10% bottom',
      },
      onComplete: () => {
        videoRef.current.play();
      }
    })

    animateWithGsap('#features_title', { y: 0, opacity: 1 })
    animateWithGsap(
      '.g_grow',
      { scale: 1, opacity: 1, ease: 'power1' },
      { scrub: 5.5 }
    );
    animateWithGsap(
      '.g_text',
      { y: 0, opacity: 1, ease: 'power2.inOut', duration: 1 }
    )
  }, []);

  return (
    <section className="h-full common-padding bg-zinc relative overflow-hidden">
      <div className="screen-max-wdith">
        <div className="mb-12 w-full flex justify-center">
          <h1 id="features_title" className="section-heading text-center text-3xl lg:text-5xl">
            🇲🇽 Conecta con marcas líderes en México y LATAM
          </h1>
        </div>

        <div className="flex flex-col justify-center items-center overflow-hidden">
          <div className="flex-center flex-col sm:px-10 mb-10">

            {/* Responsive Video Container */}
            <div className="relative w-full overflow-hidden rounded-lg aspect-video"> {/* Adjust aspect ratio */}
              <video
                playsInline
                id="exploreVideo"
                className="w-full h-full object-cover rounded-lg" // Ensure full coverage and rounded corners
                preload="auto"
                muted
                autoPlay
                loop // Add loop if needed
                ref={videoRef}
              >
                <source src={exploreVideo} type="video/mp4" />
              </video>
            </div>


            <div className="flex flex-col w-full relative mt-10"> {/* Added margin-top for spacing */}
              <div className="feature-video-container">
                <div className="overflow-hidden flex-1 h-[50vh] sm:h-auto">
                  <img src={explore1Img} alt="titanium" className="feature-video g_grow" />
                </div>
                <div className="overflow-hidden flex-1 h-[50vh] sm:h-auto">
                  <img src={explore2Img} alt="titanium 2" className="feature-video g_grow" />
                </div>
              </div>

              <div className="feature-text-container">

                <div className="flex-1 flex-center">
                  <p className="feature-text g_text text-center">
                    Sé el Primero en Vivir la Experiencia {' '}
                    <span className="text-white">
                      Únete a la lista de espera y sé uno de los primeros en explorar nuestra plataforma innovadora,
                    </span>
                    diseñada para revolucionar la forma en que interactúan creadores y marcas.
                  </p>
                </div>

                <div className="flex-1 flex-center">
                  <p className="feature-text g_text text-center">
                    Usando IA, unimos tu creatividad con grandes marcas {' '}
                    <span className="text-white">
                      para forjar historias genuinas y campañas impactantes.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Features
