import React, { useRef } from 'react';
import { chipImg, frameImg, frameVideo } from '../utils';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { animateWithGsap } from '../utils/animations';

const HowItWorks = () => {
  const videoRef = useRef();

  useGSAP(() => {
    gsap.from('#chip', {
      scrollTrigger: {
        trigger: '#chip',
        start: '20% bottom'
      },
      opacity: 0,
      scale: 2,
      duration: 2,
      ease: 'power2.inOut'
    });

    animateWithGsap('.g_fadeIn', {
      opacity: 1,
      y: 0,
      duration: 1,
      ease: 'power2.inOut'
    });
  }, []);

  return (
    <section>
      <div className="screen-max-width">
        {/* Removed my-20 class for marginTop */}
        <div id="chip" className="flex justify-center items-center w-full">
          <img
            src={chipImg}
            alt="chip"
            className="rounded-full shadow-md object-cover w-full h-full max-w-[300px] max-h-[300px]"
          />
        </div>

        <div className="flex flex-col items-center text-center">
          <p className="hiw-bigtext">
            EL FUTURO DE LAS<br />
            COLABORACIONES CREATIVAS<br />
            HA LLEGADO
          </p>

          <p className="hiw-subtitle text-lg md:text-xl text-gray-600">
            Descubre | Conecta | Brilla ✨
          </p>
        </div>



        {/* Restructure for better content grouping */}
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="mt-4 md:mt-0">
            <div className="relative h-full flex-center">
              <div className="overflow-hidden">
                <img src={frameImg} alt="frame" className="bg-transparent relative z-10" />
              </div>
              <div className="hiw-video">
                <video loop
                  className="pointer-events-none" playsInline preload="none" muted autoPlay ref={videoRef}>
                  <source src={frameVideo} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
          <div className="flex flex-col g_fadeIn">
            <ul className="hiw-text space-y-4 ml-5">
              <li className="mt-2 text-sm md:text-base">
                <span className="text-white">✨ Crea Paquetes de Videos y contenido:</span> Diseña y ofrece colecciones de contenido para tus colaboraciones
              </li>
              <li className="mt-2 text-sm md:text-base">
                <span className="text-white">✨ Llega Más Lejos:</span> Aumenta tus seguidores colaborando con marcas y proyectos emocionantes.
              </li>
              <li className="mt-2 text-sm md:text-base">
                <span className="text-white">✨ Gana con tu Contenido:</span> Obtén productos o pagos por compartir tu creatividad.
              </li>
            </ul>
            <div className="mt-4 ml-5">
              <p className="hiw-text">Innovación en</p>
              <p className="hiw-bigtext">Plataforma UGC</p>
              <p className="hiw-text">para el futuro creativo</p>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
